declare module 'o365.pwa.declaration.sw.CrudHandler.d.ts' {
    // Define necessary interfaces and types
    export interface WhereExpression {
        // Define properties for WhereExpression
    }

    export type RecordStatus = 'DESTROYED' | 'FILE-DESTROYED' | 'SYNCED' | 'UNSYNCED' | 'UPDATED' | 'CREATED' | 'DRAFT' | 'FILE-SYNCED' | 'FILE-UPDATED' | 'FILE-DRAFT' | 'FILE-CREATED';

    export interface IGroupByColumns {
        // Define properties for IGroupByColumns
    }

    export interface IGroupByAggregates {
        // Define properties for IGroupByAggregates
    }

    export interface ISortColumns {
        // Define properties for ISortColumns
    }

    export interface IO365FlatOfflineDataRecord {
        // Define properties for IO365FlatOfflineDataRecord
    }

    // Define CrudHandler type
    export const CrudHandler: {
        handleRetrieve(options: {
            appId: string;
            objectStoreId: string;
            fields: Array<{ name: string; }>;
            maxRecords?: number;
            skip?: number;
            filterObject?: any;
            whereObject?: any;
            masterDetailObject?: any;
            indexedDbWhereExpression?: WhereExpression;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
        }, allowedStatuses?: Array<RecordStatus>, disallowedStatuses?: Array<RecordStatus>): Promise<Array<object>>;

        handleRetrieveStream(options: {
            appId: string;
            objectStoreId: string;
            filterObject?: any;
            whereObject?: any;
        }, allowedStatuses?: Array<RecordStatus>, disallowedStatuses?: Array<RecordStatus>): ReadableStream;

        handleRetrieveRowcount(options: {
            appId: string;
            dataObjectId: string;
            objectStoreIdOverride?: string;
            fields: Array<{ name: string; }>;
            filterObject?: any;
            whereObject?: any;
            indexedDbWhereExpression?: WhereExpression;
            skip?: number;
            maxRecords?: number;
        }, allowedStatuses?: Array<RecordStatus>, disallowedStatuses?: Array<RecordStatus>): Promise<number>;

        handleCreate(options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            fields: Array<{ name: string; }>;
            providedRecord: IO365FlatOfflineDataRecord;
        }): Promise<IO365FlatOfflineDataRecord>;

        handleBulkCreate(options: any): Promise<void>; // Define proper type for options

        handleFileCreate(options: any): Promise<void>; // Define proper type for options

        handleUpdate(options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            providedRecord: IO365FlatOfflineDataRecord;
        }): Promise<IO365FlatOfflineDataRecord>;

        handleBulkUpdate(options: any): Promise<void>; // Define proper type for options

        handleUpdateFile(options: any): Promise<void>; // Define proper type for options

        handleDestroy(options: {
            appId: string;
            dataObjectId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
            providedRecord: IO365FlatOfflineDataRecord;
        }): Promise<boolean>;

        handleBulkDestroy(options: any): Promise<void>; // Define proper type for options

        handleDestroyFile(options: any): Promise<void>; // Define proper type for options

        getDexieInstance(options: {
            appId: string;
            objectStoreId: string;
            appIdOverride?: string;
            databaseIdOverride?: string;
            objectStoreIdOverride?: string;
        }): Promise<any>; // Define proper return type
    };
}

export {}
